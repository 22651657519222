import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const supportedLngs = ['en-DK', 'da-DK'];
const namespaces = [
  'account',
  'general',
  'rentalHousing',
  'rentalOverview',
  'applyModal',
  'newBuilds',
  'search',
  'applications',
  'application',
  'waitingListOfferConfirmation',
  'waitingList',
  'waitingListCompleteSignup',
];
const resources = supportedLngs.reduce((acc, lng) => {
  // @ts-ignore
  acc[lng] = namespaces.reduce((nsAcc, n) => {
    // @ts-ignore
    nsAcc[n] = require(`public/locales/${lng}/${n}.json`);
    return nsAcc;
  }, {});
  return acc;
}, {});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs,
    resources,
    detection: {
      order: ['path', 'navigator'],
      lookupFromPathIndex: 0,
    },
    ns: namespaces,
    defaultNS: 'general',
  });

export default i18n;
