import { stringify } from 'qs';
import { FooterCMSResult } from '../types/cms/footerTypes';
import { GeneralCMSResult } from '../types/cms/generalTypes';
import { HeaderCMSResult } from '../types/cms/headerTypes';
import { LanguageKey } from '../types/common';
import { DAWACityResult, DAWAMunicipalityResult, DAWAPostalCodeResult } from '../types/dawa';
import { ServerData } from '../types/serverData';
import { API_BASE_URL, CMS_BASE_URL } from './constants';
import { DANSK } from './languages';

export async function loadFromCms<T>(resource: string, language: LanguageKey, populate: string[] = ['*'], singleType: boolean = true): Promise<T> {
  const locale = language === 'default' ? DANSK.key : language;
  const population = [...populate, 'localizations'].map((entry) => `&populate=${entry}`).join('');
  const url = `${CMS_BASE_URL}/${resource}?sort=updatedAt:desc&locale=${locale}${population}`;
  const response = await fetch(url);
  const json = await response.json();
  if (!json.data) {
    throw Error(`No data found for resource at ${resource}. Response was: ${JSON.stringify(json)}`);
  }
  return singleType ? json.data.attributes : json.data.map((entry: { attributes: any }) => entry.attributes);
}

export async function loadFromApi(resource: string, payload: object = {}, contentType = 'text/plain;charset=UTF-8') {
  validateResource(resource);
  const url = `${API_BASE_URL}/${resource}`;
  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': contentType,
    },
  });
  await handleErrorResponse(response);

  return isResponseContentTypeJson(response) ? await response.json() : await response.text();
}

export async function deleteFromApi(resource: string, payload: object = {}, contentType = 'text/plain;charset=UTF-8') {
  validateResource(resource);
  const url = `${API_BASE_URL}/${resource}`;
  const response = await fetch(url, {
    method: 'DELETE',
    credentials: 'include',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': contentType,
    },
  });
  await handleErrorResponse(response);

  return isResponseContentTypeJson(response) ? await response.json() : await response.text();
}

export async function getFromApiPayload(resource: string, payload: object = {}, contentType = 'text/plain;charset=UTF-8') {
  validateResource(resource);
  const url = `${API_BASE_URL}/${resource}?${stringify(payload)}`;
  const response = await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': contentType,
    },
  });
  await handleErrorResponse(response);
  return isResponseContentTypeJson(response) ? await response.json() : await response.text();
}

export async function getFromApi(resource: string) {
  validateResource(resource);

  const url = `${API_BASE_URL}/${resource}`;

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'include',
  });

  await handleErrorResponse(response);
  return await response.json();
}

export async function apiPost(input: RequestInfo, params = {}): Promise<Response> {
  const body = JSON.stringify(params);
  return await fetch(`${API_BASE_URL}/${input}`, { method: 'POST', body: body, credentials: 'include' });
}

export async function uploadToApi(resource: string, payload: object = {}) {
  validateResource(resource);
  const url = `${API_BASE_URL}/${resource}`;
  const form = new FormData();
  Object.entries(payload).forEach(([key, value]) => form.append(key, value));
  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: form,
  });
  await handleErrorResponse(response);
  return await response.json();
}

export function getImageFromApiUrl(imageName: string, width: number = 384, resize: boolean = true): string {
  return `${API_BASE_URL}/image/find-public/${imageName}${resize ? `?resize=true&width=${width}` : ''}`;
}

const DAWA_POSTAL_CODE_URL = 'https://api.dataforsyningen.dk/postnumre/autocomplete?per_side=500&q=';

const DAWA_MUNICIPALITY_URL = 'https://api.dataforsyningen.dk/kommuner/autocomplete?q=';

const DAWA_POSTAL_CODE_BY_MUNICIPALITY_CODE_URL = 'https://api.dataforsyningen.dk/postnumre?per_side=500&kommunekode=';

export async function loadCities(searchTerm: string): Promise<DAWACityResult[]> {
  const response = await fetch(`${DAWA_POSTAL_CODE_URL}${searchTerm}`);
  return await response.json();
}

export async function loadMunicipalities(searchTerm: string): Promise<DAWAMunicipalityResult[]> {
  const response = await fetch(`${DAWA_MUNICIPALITY_URL}${searchTerm}`);
  return await response.json();
}

export async function loadPostalCodesByMunicipalityCode(municipalityCode: string): Promise<DAWAPostalCodeResult[]> {
  const response = await fetch(`${DAWA_POSTAL_CODE_BY_MUNICIPALITY_CODE_URL}${municipalityCode}`);
  return await response.json();
}

export async function loadSharedDataFromCMS(language: LanguageKey) {
  const headerData = await loadFromCms<HeaderCMSResult>('header', language, ['Menu.Links', 'Login', 'UserMenu, WhiteLabel']);
  const footerData = await loadFromCms<FooterCMSResult>('footer', language, ['Links']);
  const generalData = await loadFromCms<GeneralCMSResult>('general', language, [
    'PropertyDetails',
    'PropertyFinancials',
    'Collaboration',
    'Misc',
    'Misc.YesNoOptions',
    'Misc.EventBannerOpenHouse',
    'Misc.EventBannerPrivateShowing',
    'Page404.CTA',
    'BrowserNotSupported',
    'Sitemap',
    'DefaultOGImage',
  ]);
  return { headerData, footerData, generalData };
}

export async function loadAllDataFromCms<T>(
  resource: string,
  language: LanguageKey,
  populate: string[] = ['*'],
): Promise<Omit<ServerData<T>, 'languagePaths'>> {
  const { headerData, footerData, generalData } = await loadSharedDataFromCMS(language);
  const pageData = await loadFromCms<T>(resource, language, populate);
  return {
    headerData,
    footerData,
    generalData,
    pageData,
  };
}

export function getHostnameFromURL(url: string): string {
  const formattedURL = /^https?:\/\//i.test(url) ? url : `http://${url}`;
  const hostname = new URL(formattedURL).hostname;
  return hostname.replace(/^www\./, '');
}

function validateResource(resource: string) {
  if (resource.charAt(0) === '/') {
    throw new Error('resource should not start with slash');
  }
}

async function handleErrorResponse(response: Response) {
  if (response.status >= 400) {
    throw Error(await response.text());
  }
}

function isResponseContentTypeJson(response: Response) {
  return response.headers.get('content-type') === 'application/json';
}
